<template>
  <v-container>
    <h1>Home</h1>

    <v-btn color="primary" @click="teste">Teste</v-btn>
    <v-btn color="primary" @click="limpar">limpar</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "home",

  methods: {
    teste(){
      this.$store.dispatch("application/teste", { user: this.user, password: this.password }).then(() => {
      });
    },
    limpar(){
      this.$store.dispatch("application/logout").then(() => {
      });
    }
  }

};
</script>
