import Home from "@views/Home.vue";

export default [
  {
    path: "/home",
    name: "home",
    component: Home
  },
  {
    path: "/",
    alias: "/registro",
    name: "ponto",
    component: () => import("@views/Ponto.vue")
  },
  {
    path: "/registro/confirmar",
    name: "confirmar",
    component: () => import("@views/PontoConfirmar.vue")
  },
];