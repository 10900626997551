import Vue from "vue";
import App from "./App.vue";
import router from "@core/router";
import store from "@core/store";
import vuetify from "@/plugins/vuetify";
import eventBus from "@/plugins/eventBus";
import { httpSetup } from "@/plugins/http"

Vue.config.productionTip = false;

// eslint-disable-next-line no-undef
if (process.env.VUE_APP_SET !== "LOCAL") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true; 
}

Vue.use(eventBus);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");


httpSetup(store, router);