import { http } from "@/plugins/http"
import msgUtil from "@core/utils/msgUtil";

const DEFAULT = () => {
  return {
    idptoregistro: undefined,
    idptofuncionario : undefined,
    funcionarioNome : "",
    proxPos: "",
    datareg: "",
    ent1: "",
    sai1: "",
    ent2: "",
    sai2: "",
    ent3: "",
    sai3: "",
    ultRegistro: "",
    now: ""
  };
}

const state = {
  registro: {},
  dataAtual: undefined,
  funcionario: {
    cpf: "",
    datanasc: ""
  },
};

const actions = {
  load: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      http.get(`/ponto/${payload.cpf}/${payload.datanasc}`)
        .then(response => {
          commit("setRegistro", response.data);
          resolve();
        })
        .catch(erro => {
          msgUtil.error(erro);
          reject();
        });
    });
  },
  registrar: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      http.post(`/ponto/${state.funcionario.cpf}/${state.funcionario.datanasc}`)
        .then(response => {
          msgUtil.info("Ponto Registrado com sucesso.<br/>"+response.data.ultRegistro);

          commit("reset");
          resolve();
        })
        .catch(erro => {
          msgUtil.error(erro);
          reject();
        });
    });
  },
  reset: ({ commit }) => {
    commit("reset");
  },
  dataAtual: ({ commit }) => {
    commit("setDataAtual", "-");
    return new Promise((resolve, reject) => {
      http.get("/app/now")
        .then(response => {
          commit("setDataAtual", response.data.dataHora);
          resolve();
        })
        .catch(erro => {
          msgUtil.error(erro);
          reject();
        });
    });
  },
}

const mutations = {
  setDataAtual: (state, data) => {
    state.dataAtual = data;
  },
  setRegistro: (state, registro) => {
    state.registro = { ...registro };
  },
  reset: state => {
    state.registro  = { ...DEFAULT() };
    state.dataAtual = undefined;
    state.funcionario.cpf       = "" ;
    state.funcionario.datanasc  = "" ;    
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};