import Vue from "vue";
import Vuex from "vuex";
import application from "./application";

Vue.use(Vuex);

// Carrega os demais arquivos como modulos
const modules = {};
// eslint-disable-next-line no-undef
const requireComponent = require.context("@/store", true, /\w+(?<!index)\.js/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const names = fileName.split("/");

  // Sub-Pasta, adicionar com o namespace
  if (names.length == 3){
    componentConfig.default && (modules[names[1] +"/"+ names[2].replace(/\.js+$/, "")] = componentConfig.default);
  } else {
    componentConfig.default && (modules[names[1].replace(/\.js+$/, "")] = componentConfig.default);
  }
});

export default new Vuex.Store({
  modules: {
    application,
    ...modules
  }
});