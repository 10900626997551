<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color="messages.color"
      :timeout="6000"
      :multi-line="true"
      :bottom="true"
      :right="true">
      <span v-html="messages.message" />
      <v-btn dark text @click="snackbar = false">Fechar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      snackbar: false,
      messages: {
        message: "",
        color: ""
      }
    };
  },
  mounted() {
    this.$bus.$on("msgSnackbar", obj => {
      const { message, color } = obj;
      this.messages.message = message;
      this.messages.color = color;
      this.snackbar = true;
    });
  }
};
</script>

<style scoped></style>
