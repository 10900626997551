import { http, httpSetToken } from "@/plugins/http"
import qs from "qs";

/**
 * Constantes do Local Storage
 */
const STORAGE_USER = "user";
const STORAGE_TOKEN = "token";
const STORAGE_TOKEN_REFRESH = "token_refresh";

const state = {
  sistema: {
    versao: "1.3.0",
    atualizacao: "11/10/2024"
  },
  usuario: {
    user: sessionStorage.getItem(STORAGE_USER) || "",
    token: sessionStorage.getItem(STORAGE_TOKEN) || "",
    token_refresh: sessionStorage.getItem(STORAGE_TOKEN_REFRESH) || ""
  },
  menu: []
};

const getters = {
  isAutenticado: state => !!state.usuario.token
};

const actions = {
  login: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      const data = qs.stringify({
        grant_type: "password",
        username: payload.user,
        password: payload.password
      });
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      };

      http.post("/token", data, headers)
        .then(response => {
          commit("auth_success", {
            user: response.data.uuidusuario,
            token: response.data.access_token,
            token_refresh: response.data.access_token
          });
          httpSetToken();
          resolve();
        })
        .catch(erro => {
          commit("auth_clear");
          //msgUtil.error(erro);
          reject();
        });
    });
  },

  logout: ({ commit }) => {
    commit("auth_clear");
    httpSetToken();
  },

  refreshToken: ({ commit }) => {
    return new Promise((resolve, reject) => {
      const data = qs.stringify({
        refresh_token: "refresh_token",
      });
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      };

      http.post("/token", data, headers).then(response => {
          // Limpa commit("auth_clear");

          if (response.status === 200) {
            commit("auth_success", {
              user: response.data.user,
              token: response.data.token,
              token_refresh: response.data.refreshToken
            });
            httpSetToken();
            resolve();
          }
        })
        .catch(erro => {
          console.log(erro);

          //console.log(erro.response)
          //msgUtil.error(erro);
          reject();
        });
    });
  },
};

const mutations = {
  auth_success(state, payload){
    sessionStorage.setItem(STORAGE_USER, payload.user);
    sessionStorage.setItem(STORAGE_TOKEN, payload.token);
    sessionStorage.setItem(STORAGE_TOKEN_REFRESH, payload.token_refresh);

    // Atualizar o State
    state.usuario.user = payload.user;
    state.usuario.token = payload.token;
    state.usuario.token_refresh = payload.token_refresh;
  },
  auth_clear(state){
    sessionStorage.clear();

    // Atualizar o State
    state.usuario.user = "";
    state.usuario.token = "";
    state.usuario.token_refresh = "";
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
