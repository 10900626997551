<template>
  <div>
    <v-progress-linear absolute :active="loading" :indeterminate="true" :height="4" class="ma-0" color="secondary" />
  </div>
</template>

<script>
export default {
  name: "Load",
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.$bus.$on("loading", loading => {
      this.loading = loading;
    });
  }
};
</script>

<style scoped>

</style>
