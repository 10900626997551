<template>
  <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">Ops! Erro 404</h1>
        <p>Página não encontrada.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Erro404",
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  }
};
</script>
