import Vue from "vue";
import Router from "vue-router";
import Erro404 from "@core/views/Erro404.vue";
import routerApp from "@/router";
import application from "@core/store/application";

Vue.use(Router);

const routes = [
  ...routerApp,
  // {
  //   path: "/",
  //   name: "login",
  //   component: () => import("@core/views/Login.vue")
  // },
  {
    path: "*",
    component: Erro404
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // // Páginas Liberadas
  // if (to.path == "/") {
  //   next();
  //   return;
  // }

  // //se não tiver token do usuário, retorne à tela de login.
  // if (application.state.usuario.token == "") {
  //   next("/");
  //   return;
  // }

  // Segue o fluxo normal
  next();
});

export default router;
