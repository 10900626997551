import axios from "axios";
import { bus } from "@/plugins/eventBus";
import msgUtil from "@core/utils/msgUtil";

function load(loading) {
  if (loading) {
    bus.$emit("loading", true);
  } else {
    setTimeout(function() {
      bus.$emit("loading", false);
    }, 500);
  }
}

export const http = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_URL
});

// Defaults Headers
http.defaults.headers.common["Accept"] = "application/json";
http.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export const httpSetToken = () => {
  //** ATRIBUIÇÃO DE TOKEN */
  // let token = sessionStorage.getItem("token") || "";

  // if (token == "") {
  //   delete http.defaults.headers.common["Authorization"];
  // } else {
  //   http.defaults.headers.common["Authorization"] = `Bearer ${token}`
  // }

  // eslint-disable-next-line no-undef
  http.defaults.headers.common["Authorization"] = `Basic ${process.env.VUE_APP_SECRET}`;
};


export const httpSetup = (store, router) => {
  http.defaults.headers.common["Sistema-Versao"] = store.state.application.sistema.versao;

  // Intercepta a Requisição
  http.interceptors.request.use(config => {
    load(true);
    return config;
    /*switch(response.status){
      case 200:
        return response;
        break;
      default:
    }*/
  }, error => {
    load(false);
    return Promise.reject(error);
  });

  // Intecepta a Resposta
  http.interceptors.response.use(
    response => {
      load(false);
      return response;
    },
    error => {
      load(false);

      if (!error.response) {
        msgUtil.error("Ops! Servidor indisponível. Verifique sua internet e tente novamente.");
        return new Promise(() => {});
      }

      const originalRequest = error.config;

      //if (error.response.status === 401 && originalRequest.url === "http://13.232.130.60:8081/v1/auth/token") {
      if (error.response.status === 401 && originalRequest._retry) {
        router.push("/");
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        store.dispatch("application/refreshToken");

        return http(originalRequest);
      }

      if (error.response.status === 403) {
        msgUtil.error("Ops! Acesso negado.");
        return new Promise(() => {});
      }

      if (error.response.status === 404) {
        msgUtil.error("Ops! Serviço não encontrado.");
        return new Promise(() => {});
      }

      if (error.response.status === 405) {
        msgUtil.error("Ops! Método não permitida.");
        return new Promise(() => {});
      }

      if ( (error.response.status === 400) && (error.response.data && error.response.data.error) ){
        msgUtil.error(error.response.data.error);
        return new Promise(() => {});
      }

      if(error.response.status === 500 && error.response.data && error.response.data.erro) {
        msgUtil.error(error.response.data.erro);
        return new Promise(() => {});
      }

      if(error.response.status === 500 && error.response.data && error.response.data.message &&
        error.response.data.message.indexOf("optimistic locking failed; nested exception is org.hibernate.StaleObjectStateException") >= 0 ) {
        msgUtil.error("Ops! O Registro foi atualizado ou excluído por outro usuário. <br/>Atualize e tente novamente.");
        return new Promise(() => {});
      }

      return Promise.reject(error);
    }
  );
};
