import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FF5722",
        //light: "#F4F4F4",
        //lighter: "#FFFFFF",
        secondary: "#FF0266",

        //
        accent: "#FF9E80",
        error: "#F44336",
        critical: "#F44336",
        //
        warning: "#FABE58",
        info: "#2196F3",
        cancel: "#808080",
        success: "#2ECC71",
        grey: "#6c7a89"
      },
    },
  },
})