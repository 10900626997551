import { bus } from "@/plugins/eventBus";

const msgUtil = {
  info(msg) {
    bus.$emit("msgSnackbar", { message: msg, color: "info" });
  },
  success(msg) {
    bus.$emit("msgSnackbar", { message: msg, color: "success" });
  },
  warning(msg) {
    bus.$emit("msgSnackbar", { message: msg, color: "warning" });
  },
  error(msg) {
    bus.$emit("msgSnackbar", { message: msg, color: "error" });
  }
};

export default msgUtil;
